<template>
  <div class="flex items-center mb-4 gap-3 w-full">
    <searchbar
      :id="'search'"
      :label="$t('search')"
      v-model="state.search"
      :labelClass="'bg-white-dark'"
      :activeColor="'primary'"
      class="w-full sm:w-1/3 "
      :placeHolder="$t('search')"
      @update:modelValue="debounceSearchCollis"
    />

    <div
      class="ml-auto flex items-center cursor-pointer gap-4 md:gap-4"
      v-if="productCarrierView"
    >
      <div
        class="flex items-center bg-primary px-2 py-2 rounded-lg"
        @click="$emit('importcsv')"
      >
        <div class="sm:mr-2">
          <import-file-icon :fillClass="'fill-white'" />
        </div>
        <div class="lg:text-md text-white font-semibold  sm:block hidden">
          {{ $t("import_csv") }}
        </div>
      </div>
      <div
        class="ml-auto flex items-center cursor-pointer bg-primary px-2 py-2 rounded-lg"
        v-if="productCarrierView"
        @click="$emit('add_product_carrier')"
      >
        <div class="mr-0 sm:mr-2"><plus-icon :fillClass="'fill-white'" /></div>
        <div class="text-md text-white font-semibold hidden sm:block">
          {{ $t("add_product_carrier") }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, reactive } from "vue";
import Searchbar from "../../../components/layout/Searchbar.vue";
import PlusIcon from "../../../components/icons/PlusIcon.vue";
import { useStore } from "vuex";
import Debouncer from "../../../common/debouncer";
import { useRoute } from "vue-router";
import {
  AUTH_TYPES,
  CUSTOMER_COLLIS_TYPES,
  STORE_TYPES,
} from "../../../store/types";

export default {
  name: "SearchBox",
  components: { Searchbar, PlusIcon },
  emits: ["open", "add_product_carrier", "update"],

  props: {
    productCarrierView: {
      type: Boolean,
      default: false,
    },
    placeHolder: {
      type: String,
      default: null,
    },
  },

  setup() {
    const store = useStore();
    const route = useRoute();
    const state = reactive({
      debouncer: new Debouncer(),
      search: "",
    });

    const GET_USER = computed(() => {
      return store.getters[`${STORE_TYPES.AUTH}/${AUTH_TYPES.GET_USER}`];
    });

    function FETCH_CUSTOMER_COLLIS() {
      const dataColli = {
        id: GET_USER.value.customer_id,
        params: { search: state.search, page: 1 },
      };
      store.dispatch(
        `${STORE_TYPES.CUSTOMER_COLLIS}/${CUSTOMER_COLLIS_TYPES.FETCH_CUSTOMER_COLLIS}`,
        dataColli
      );
    }
    const debounceSearchCollis = () => {
      state.debouncer.debounce(FETCH_CUSTOMER_COLLIS);
    };

    return {
      state,
      route,
      debounceSearchCollis,
    };
  },
};
</script>
