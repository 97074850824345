<template>
  <middle-modal
    :isVisible="isVisible"
    @closeModal="$emit('closeImportModal')"
    :title="$t('import_csv_file')"
  >
    <div class="flex flex-col w-full">
      <div class="relative" ref="searchbar">
        <input-text
          :id="'csv_file'"
          :name="'csv_file'"
          :placeHolder="placeHolder"
          @click="$refs.fileinput.click()"
          class="m-4"
        >
          <template #icon>
            <file-icon :fillClass="'fill-black'"> </file-icon>
          </template>
        </input-text>
      </div>
      <input
        id="file-upload"
        ref="fileinput"
        type="file"
        hidden
        @change="updateInput()"
      />

      <div class="flex gap-5 mt-5">
        <custom-button
          class="h-11 w-1/2"
          :bgColor="'bg-grey-fontgrey'"
          :textColor="'text-white'"
          :text="$t('abort')"
          @click="$emit('closeImportModal')"
        />

        <custom-button
          class="h-11 w-1/2 "
          :bgColor="'bg-primary'"
          :textColor="'text-white'"
          :text="$t('start_import')"
        />
      </div>
    </div>
  </middle-modal>
</template>

<script>
import { reactive, ref } from "@vue/reactivity";
import CustomButton from "../../../components/input/CustomButton.vue";
import InputText from "../../../components/input/InputText.vue";

import MiddleModal from "../../../components/layout/MiddleModal.vue";
import { useStore } from "vuex";
import FileIcon from "../../../components/icons/fileIcon.vue";

export default {
  components: {
    MiddleModal,
    InputText,
    CustomButton,
    FileIcon,
  },

  emits: ["closeImportModal"],

  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    placeHolder: {
      type: String,
    },
  },

  setup() {
    const store = useStore();
    /**
     *  Refs
     */
    const fileinput = ref();

    /**
     *  State
     */
    const state = reactive({});

    /**
     * emits
     */

    /**
     * Functions
     */

    // importCsvFile(event) {
    //     let formData = new FormData();
    //     formData.append("file", event.target.files[0]);
    //     // this.uploadCsvFile(formData);
    //     event.target.value = null;
    //     return;
    //   },

    return { state, fileinput, store };
  },
};
</script>
