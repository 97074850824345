<template>
  <div>
    <administration-search-box
      :productCarrierView="true"
      @add_product_carrier="add_product_carrier()"
      @importcsv="import_csv_file()"
    />
    <div
      class="md:w-full h-full transition-all overflow-auto scrollbar scrollbar-dark pr-1 md:mr-1"
      id="colliScrollBox"
      :class="GET_CUSTOMER_COLLIS ? 'w-full' : 'w-full'"
    >
      <div
        class="bg-white cursor-pointer rounded-md h-auto flex-1 gap-8 mb-3  hover:border-grey-fontgrey"
        :class="
          GET_CUSTOMER_COLLI.id == colli.id
            ? 'border-t-4 border-primary'
            : 'border border-transparent'
        "
        v-for="colli of GET_CUSTOMER_COLLIS"
        :key="colli.id"
        :colli="colli"
        @click="edit_colli(colli)"
      >
        <!--  ON ACTIVE   SHOW IF ...  -->
        <collis-info-container
          :colli="colli"
          :administrationView="true"
          @editColli="edit_colli(colli)"
        />
      </div>
      <div class="w-full flex justify-center items-center">
        <infinite-loading
          :scrollBoxId="'colliScrollBox'"
          :isActive="!GET_ALL_COLLIS_LOADED"
          :emptyText="$t('no_more_collis')"
          @load="loadCollis()"
        />
      </div>
    </div>
    <add-product-carrier-modal
      :fetchProp="false"
      :isVisible="state.openModal"
      @closeModal="set_back()"
    />

    <import-file-modal
      :isVisible="state.openImportModal"
      @closeImportModal="state.openImportModal = false"
    />
  </div>
</template>

<script>
import { computed, defineComponent, reactive } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import {
  AUTH_TYPES,
  CUSTOMER_COLLIS_TYPES,
  STORE_TYPES,
} from "../../../store/types";
import { getData } from "../../../common/dataHelper";
import AddProductCarrierModal from "../../finders/modals/AddProductCarrierModal.vue";
import CollisInfoContainer from "../components/CollisInfoContainer.vue";
import AdministrationSearchBox from "../components/CollisSearchBox.vue";
import ImportFileModal from "../modals/ImportFileModal.vue";
import InfiniteLoading from "../../../components/global/InfiniteLoading.vue";

export default defineComponent({
  components: {
    AdministrationSearchBox,
    AddProductCarrierModal,
    ImportFileModal,
    CollisInfoContainer,
    InfiniteLoading,
  },

  setup() {
    const store = useStore();
    const route = useRoute();
    /** State */
    const state = reactive({
      search: "",
      openModal: false,
    });

    /*
     * Computeds
     */
    const GET_CUSTOMER_COLLIS = computed(() => {
      return store.getters[
        `${STORE_TYPES.CUSTOMER_COLLIS}/${CUSTOMER_COLLIS_TYPES.GET_CUSTOMER_COLLIS}`
      ];
    });
    const GET_USER = computed(() => {
      return store.getters[`${STORE_TYPES.AUTH}/${AUTH_TYPES.GET_USER}`];
    });
    const GET_PAGE = computed(() => {
      return store.getters[
        `${STORE_TYPES.CUSTOMER_COLLIS}/${CUSTOMER_COLLIS_TYPES.GET_PAGE}`
      ];
    });

    const GET_ALL_COLLIS_LOADED = computed(() => {
      return store.getters[
        `${STORE_TYPES.CUSTOMER_COLLIS}/${CUSTOMER_COLLIS_TYPES.GET_ALL_COLLIS_LOADED}`
      ];
    });
    const GET_CUSTOMER_COLLI = computed(() => {
      return store.getters[
        `${STORE_TYPES.CUSTOMER_COLLIS}/${CUSTOMER_COLLIS_TYPES.GET_CUSTOMER_COLLI}`
      ];
    });

    function FETCH_CUSTOMER_COLLIS() {
      const dataColli = {
        id: GET_USER.value.customer_id,
        params: {
          search: state.search,
          page: GET_PAGE.value + 1,
        },
      };
      store.dispatch(
        `${STORE_TYPES.CUSTOMER_COLLIS}/${CUSTOMER_COLLIS_TYPES.FETCH_CUSTOMER_COLLIS}`,
        dataColli
      );
    }

    const filteredCollis = computed(() => {
      return store.getters[
        `${STORE_TYPES.CUSTOMER_COLLIS}/${CUSTOMER_COLLIS_TYPES.GET_CUSTOMER_COLLIS}`
      ].filter((colli) => {
        if (state.search == "" || state.search == null) {
          return true;
        }
        if (
          colli.name != null &&
          colli.name.toLowerCase().match(state.search.toLowerCase())
        ) {
          return true;
        }
        const dataAttributes = getData(colli, "data.data");

        if (dataAttributes) {
          for (const key in dataAttributes) {
            if (Object.hasOwnProperty.call(dataAttributes, key)) {
              const element = dataAttributes[key];
              if (
                element &&
                element
                  .toString()
                  .toLowerCase()
                  .match(state.search.toLowerCase())
              )
                return true;
            }
          }
        }

        return false;
      });
    });

    /** Functions */

    function FETCH_COLLI() {
      const colliData = {
        customer_id: GET_CUSTOMER_COLLI.value.customer_id,
        id: GET_CUSTOMER_COLLI.value.id,
      };
      store.dispatch(
        `${STORE_TYPES.CUSTOMER_COLLIS}/${CUSTOMER_COLLIS_TYPES.FETCH_CUSTOMER_COLLI}`,
        colliData
      );
    }
    const SET_CUSTOMER_COLLI = (colli) => {
      return store.commit(
        `${STORE_TYPES.CUSTOMER_COLLIS}/${CUSTOMER_COLLIS_TYPES.SET_CUSTOMER_COLLI}`,
        colli
      );
    };
    const import_csv_file = () => {
      state.openImportModal = true;
    };
    const add_product_carrier = () => {
      SET_CUSTOMER_COLLI({});
      state.openModal = true;
    };
    const set_back = () => {
      SET_CUSTOMER_COLLI({});
      state.openModal = false;
    };
    const edit_colli = (colli) => {
      SET_CUSTOMER_COLLI(colli);
      FETCH_COLLI();
      state.openModal = true;
    };

    function loadCollis() {
      FETCH_CUSTOMER_COLLIS();
    }

    /**
     *  Mounted
     */

    return {
      store,
      state,
      route,
      add_product_carrier,
      edit_colli,
      import_csv_file,
      GET_CUSTOMER_COLLIS,
      GET_CUSTOMER_COLLI,
      FETCH_CUSTOMER_COLLIS,
      filteredCollis,
      set_back,
      loadCollis,
      GET_ALL_COLLIS_LOADED,
    };
  },
});
</script>
